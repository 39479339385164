.navbar.navbarTop {
    height: 56px;
    background-color: #3097d1;
}

.navbar .linkButton {
    font-size: calc( 16px + (24 - 16) * (100vw - 400px) / (800 - 400) );
}

.navbar .fa-plus {
    color: #2ecc71;
}

.navbar .dropdown-item {
    color: #292b2c;
}

.navbar .modal .modal-body {
    padding: 0;
}

.navbar .modal .modal-body .list-group .list-group-item {
    border: 0;
}
.navbar .modal .modal-body .list-group .list-group-item a {
    color: black;
}

.navbar .nav-item .show > .btn-outline-light.dropdown-toggle {
    background-color: transparent;
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.navbar .nav-item .btn-outline-light:hover {
    background-color: transparent;
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.navbar .nav-item .btn-quickswitch {
    margin-top: 2px;
}

.navbar .nav-link .fa-bell .badge {
    position: relative;
    top: -15px;
    left: -15px;
    font-size: 50%;
}