#app-auth {
    display: table;
    width: 100%;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

#app-auth h5 {
    color: #8d99a0;
}

#app-auth .smack-in-the-middle {
    display: table-cell;
    vertical-align: middle;
}

#app-auth .logo {
    margin: 0 auto 30px auto;
    max-width: 200px;
    display: block;
}

#app-auth .form-control {
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.6;
    color: #536570;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

#app-auth .has-danger .g-recaptcha iframe {
    border: 1px solid #d9534f;
}

#app-auth .g-recaptcha div:first-child {
    margin: 0 auto 8px auto;
}

#app-auth .buttons{
    margin-bottom: 60px;
}

#app-auth footer a{
    color: #9da9b0;
}