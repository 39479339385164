html {
    font-family: 'Work Sans', sans-serif;
    font-size: 10px;
    line-height: 1;
}
body {
    color: #1e3948;
    background-color: #f5f8fa;
    margin-bottom: 10px;
    padding-top: 70px;
    padding-bottom: 50px;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}