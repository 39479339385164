.createPost {
    font-size: 2rem;
}

.createPost .col-12{
   padding-left: 1px;
   padding-right: 1px;
}

.createPost textarea{
    height: 200px
}