html {
    font-family: 'Work Sans', sans-serif;
    font-size: 10px;
    line-height: 1;
}
body {
    color: #1e3948;
    background-color: #f5f8fa;
    margin-bottom: 10px;
    padding-top: 70px;
    padding-bottom: 50px;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
#app-auth {
    display: table;
    width: 100%;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

#app-auth h5 {
    color: #8d99a0;
}

#app-auth .smack-in-the-middle {
    display: table-cell;
    vertical-align: middle;
}

#app-auth .logo {
    margin: 0 auto 30px auto;
    max-width: 200px;
    display: block;
}

#app-auth .form-control {
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.6;
    color: #536570;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

#app-auth .has-danger .g-recaptcha iframe {
    border: 1px solid #d9534f;
}

#app-auth .g-recaptcha div:first-child {
    margin: 0 auto 8px auto;
}

#app-auth .buttons{
    margin-bottom: 60px;
}

#app-auth footer a{
    color: #9da9b0;
}
.error-page {
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.error-page h1 {
    font-size: 30vh;
    font-weight: bold;
    position: relative;
    margin: -8vh 0 0;
    padding: 0;
}
.error-page h1:after {
    content: attr(data-h1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    /* webkit only for graceful degradation to IE */
    background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #69a6ce, #b98acc, #ee8176, #b98acc, #69a6ce, #9b59b6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400%;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
    -webkit-animation: animateTextBackground 10s ease-in-out infinite;
            animation: animateTextBackground 10s ease-in-out infinite;
}
.error-page h1 + p {
    color: #d6d6d6;
    font-size: 8vh;
    font-weight: bold;
    line-height: 10vh;
    max-width: 600px;
    position: relative;
}
.error-page h1 + p:after {
    content: attr(data-p);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

@-webkit-keyframes animateTextBackground {
    0% {
        background-position: 0 0;
    }
    25% {
        background-position: 100% 0;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0 100%;
    }
    100% {
        background-position: 0 0;
    }
}

@keyframes animateTextBackground {
    0% {
        background-position: 0 0;
    }
    25% {
        background-position: 100% 0;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 0 100%;
    }
    100% {
        background-position: 0 0;
    }
}
@media (max-width: 767px) {
    .error-page h1 {
        font-size: 32vw;
    }
    .error-page h1 + p {
        font-size: 8vw;
        line-height: 10vw;
        max-width: 70vw;
    }
}
.bm-menu-wrap {
    margin-top: -14px;
}

.bm-menu {
    font-size: 1.3rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0;
    border-right: 0;
    padding-bottom: 20px;
    background: white;
}

.bm-menu .bm-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
button.linkButton,
button.linkButton:hover,
button.linkButton:active,
button.linkButton:focus,
button.linkButton.is-focused:not(:active),
button.linkButton:focus:not(:active),
.button.linkButton,
.button.linkButton:hover,
.button.linkButton:active,
.button.linkButton:focus,
.button.linkButton.is-focused:not(:active),
.button.linkButton:focus:not(:active)
{
    background-color: transparent;
    border-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    outline: 0;
    margin: 0;
    padding: 0;
    position: relative;
}
.navbar.navbarTop {
    height: 56px;
    background-color: #3097d1;
}

.navbar .linkButton {
    font-size: calc( 16px + (24 - 16) * (100vw - 400px) / (800 - 400) );
}

.navbar .fa-plus {
    color: #2ecc71;
}

.navbar .dropdown-item {
    color: #292b2c;
}

.navbar .modal .modal-body {
    padding: 0;
}

.navbar .modal .modal-body .list-group .list-group-item {
    border: 0;
}
.navbar .modal .modal-body .list-group .list-group-item a {
    color: black;
}

.navbar .nav-item .show > .btn-outline-light.dropdown-toggle {
    background-color: transparent;
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.navbar .nav-item .btn-outline-light:hover {
    background-color: transparent;
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.navbar .nav-item .btn-quickswitch {
    margin-top: 2px;
}

.navbar .nav-link .fa-bell .badge {
    position: relative;
    top: -15px;
    left: -15px;
    font-size: 50%;
}
.navbarBottom {

}
.navbarBottom a {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card.priblo_post .badge {
    position: absolute;
    top: -4px;
    right: -6px;
}
.card.priblo_post {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    position: relative;
    border-radius: 0;
}
.card.priblo_post .post-actions {
    padding: 10px 22px;
    color: #999;
    display: inline-block;
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 0;
    text-align: left;
    border-bottom: 1px solid #EDEDED;
    line-height: 1.6;
}
.card.priblo_post .post-actions ul {
    margin-top: 0;
    margin-bottom: 0;
}
.card.priblo_post .post-actions ul li.list-inline-item {
    margin-right: 1.2rem;
}

.card.priblo_post .card-body .card-text {
    font-family: 'Raleway', sans-serif;
    font-size: 1.8rem;
    line-height: 1.4;
}

.card.priblo_post .blog-post-meta {
    padding: 0 8px;
    color: #999;
    display: block;
}
.card.priblo_post .blog-post-meta p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.card.priblo_post .card-img-top {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.card.notification-read {
    background: #e8e8e8;
}
.priblo_comments .card {
    margin-bottom: 0;
    background: transparent;
    border: 0;
    font-size: 0.9em;
    padding: 0 15px;
    line-height: 1em;
}

.priblo_comments .card .card-body {
    margin: 0;
    padding: 0.5rem;
}

.priblo_comments .card .card-body.disabled {
    color: gainsboro;
    text-decoration: line-through;
}

.priblo_comments .card .card-body p{
    margin: 0;
    padding: 0;
}

.priblo_comments .card .card-body p strong{
    font-size: 1.1em;
    font-weight: bold;
}

.priblo_comments .card .card-body p.timestamp{
    font-size: 0.5em;
}
.priblo_comments {
    border-top: 1px solid #EDEDED;
    font-family: 'Raleway', sans-serif;
    font-size: 1.5rem;
}

.priblo_comments h5{
    font-size: 1.5em;
    padding: 5px 20px;
}

.priblo_comments form {
    margin: 0;
    margin-top: 7px;
    padding: 0;
}
.priblo_comments form input {
    height: 40px;
    font-size: 0.9em;
    font-weight: bold;
}
.priblo_comments form input.is-invalid::-webkit-input-placeholder {
    color: #dc3545;
    opacity: 1; /* Firefox */
}
.priblo_comments form input.is-invalid::-ms-input-placeholder {
    color: #dc3545;
    opacity: 1; /* Firefox */
}
.priblo_comments form input.is-invalid::placeholder {
    color: #dc3545;
    opacity: 1; /* Firefox */
}
.priblo_comments form .input-group button{
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-left: 1px solid #EDEDED;
    border-top: 1px solid #EDEDED;
}

.priblo_comments form input, .priblo_comments form input:focus {
    border: 0;
    padding: 5px 22px;
    margin: 0;
    border-top: 1px solid #EDEDED;
    outline: 0;
    box-shadow: none;
}

.priblo_timeline_posts .col-12 {
    padding-left: 1px;
    padding-right: 1px;
}

.priblo_timeline_controls {
    margin-bottom: 10px;
}
.attachment-do-attach i.spinner {
    position: relative;
    padding: 10px 20px 10px 34px;
    display: inline-block;
}
.attachables {
    position: absolute;
    z-index: 10001;
    width: 100%;
    top: 20px;
}
.attachables .row {
    margin-bottom: 5px;
}
.attachables .attachment-filterable img {
    max-width: 100%;
}
.attachables .attachment-filterable .controls {
    margin-top: 8px;
}
.attachables .attachment-attachable {
    position: absolute;
    z-index: 10000;
    width: 100%;
}
.attachables .attachment-attachable .controls {
    margin-top: 8px;
}
.attachment-area .attachment-attached {
    position: relative;
}
.attachment-area .attachment-attached a {
    position: absolute;
    top: -15px;
    right: -10px;
    z-index: 100;
}
.attachment-area .attachment-attached a i {
    color: #ff2a21;
}
.attachment-area .attachment-attached img {
    max-width: 100%;
    max-height: 100%;
}
.attachment-area-templates {
    width: 0;
    height: 0;
    visibility: hidden;
}
.attachment-area {
    background-color: #efefef;
    border: 3px dashed #ccc;
    border-radius: 10px;
    text-align: center;
    padding: 25px;
}

.createPost {
    font-size: 2rem;
}

.createPost .col-12{
   padding-left: 1px;
   padding-right: 1px;
}

.createPost textarea{
    height: 200px
}
