.priblo_comments .card {
    margin-bottom: 0;
    background: transparent;
    border: 0;
    font-size: 0.9em;
    padding: 0 15px;
    line-height: 1em;
}

.priblo_comments .card .card-body {
    margin: 0;
    padding: 0.5rem;
}

.priblo_comments .card .card-body.disabled {
    color: gainsboro;
    text-decoration: line-through;
}

.priblo_comments .card .card-body p{
    margin: 0;
    padding: 0;
}

.priblo_comments .card .card-body p strong{
    font-size: 1.1em;
    font-weight: bold;
}

.priblo_comments .card .card-body p.timestamp{
    font-size: 0.5em;
}