.card.priblo_post {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    position: relative;
    border-radius: 0;
}
.card.priblo_post .post-actions {
    padding: 10px 22px;
    color: #999;
    display: inline-block;
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 0;
    text-align: left;
    border-bottom: 1px solid #EDEDED;
    line-height: 1.6;
}
.card.priblo_post .post-actions ul {
    margin-top: 0;
    margin-bottom: 0;
}
.card.priblo_post .post-actions ul li.list-inline-item {
    margin-right: 1.2rem;
}

.card.priblo_post .card-body .card-text {
    font-family: 'Raleway', sans-serif;
    font-size: 1.8rem;
    line-height: 1.4;
}

.card.priblo_post .blog-post-meta {
    padding: 0 8px;
    color: #999;
    display: block;
}
.card.priblo_post .blog-post-meta p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.card.priblo_post .card-img-top {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.card.notification-read {
    background: #e8e8e8;
}