.priblo_comments {
    border-top: 1px solid #EDEDED;
    font-family: 'Raleway', sans-serif;
    font-size: 1.5rem;
}

.priblo_comments h5{
    font-size: 1.5em;
    padding: 5px 20px;
}

.priblo_comments form {
    margin: 0;
    margin-top: 7px;
    padding: 0;
}
.priblo_comments form input {
    height: 40px;
    font-size: 0.9em;
    font-weight: bold;
}
.priblo_comments form input.is-invalid::placeholder {
    color: #dc3545;
    opacity: 1; /* Firefox */
}
.priblo_comments form .input-group button{
    border-radius: 0;
    box-shadow: none;
    border: 0;
    border-left: 1px solid #EDEDED;
    border-top: 1px solid #EDEDED;
}

.priblo_comments form input, .priblo_comments form input:focus {
    border: 0;
    padding: 5px 22px;
    margin: 0;
    border-top: 1px solid #EDEDED;
    outline: 0;
    box-shadow: none;
}
