button.linkButton,
button.linkButton:hover,
button.linkButton:active,
button.linkButton:focus,
button.linkButton.is-focused:not(:active),
button.linkButton:focus:not(:active),
.button.linkButton,
.button.linkButton:hover,
.button.linkButton:active,
.button.linkButton:focus,
.button.linkButton.is-focused:not(:active),
.button.linkButton:focus:not(:active)
{
    background-color: transparent;
    border-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    outline: 0;
    margin: 0;
    padding: 0;
    position: relative;
}