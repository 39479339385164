.bm-menu-wrap {
    margin-top: -14px;
}

.bm-menu {
    font-size: 1.3rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0;
    border-right: 0;
    padding-bottom: 20px;
    background: white;
}

.bm-menu .bm-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}