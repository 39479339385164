.attachment-do-attach i.spinner {
    position: relative;
    padding: 10px 20px 10px 34px;
    display: inline-block;
}
.attachables {
    position: absolute;
    z-index: 10001;
    width: 100%;
    top: 20px;
}
.attachables .row {
    margin-bottom: 5px;
}
.attachables .attachment-filterable img {
    max-width: 100%;
}
.attachables .attachment-filterable .controls {
    margin-top: 8px;
}
.attachables .attachment-attachable {
    position: absolute;
    z-index: 10000;
    width: 100%;
}
.attachables .attachment-attachable .controls {
    margin-top: 8px;
}
.attachment-area .attachment-attached {
    position: relative;
}
.attachment-area .attachment-attached a {
    position: absolute;
    top: -15px;
    right: -10px;
    z-index: 100;
}
.attachment-area .attachment-attached a i {
    color: #ff2a21;
}
.attachment-area .attachment-attached img {
    max-width: 100%;
    max-height: 100%;
}
.attachment-area-templates {
    width: 0;
    height: 0;
    visibility: hidden;
}
.attachment-area {
    background-color: #efefef;
    border: 3px dashed #ccc;
    border-radius: 10px;
    text-align: center;
    padding: 25px;
}
